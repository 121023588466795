<template>
  <div :class="$style.root">
    <VRow>
      <VCol v-for="(value, index) in values" cols="12" :key="index">
        <VBtn v-if="values.length > +required" icon :class="$style.remove">
          <VIcon @click="values.splice(index, 1)" color="error">
            mdi-close
          </VIcon>
        </VBtn>
        <template v-if="options">
          <VAutocomplete v-if="type === 'autocomplete'" :items="search ? [...options, search] : options" :label="label" :value="value" :search-input.sync="search" :readonly="readonly" :disabled="disabled" @change="(value) => $emit('update:values', insert(values, index, value))" @blur="(value) => $emit('update:values', insert(values, index, search))" :rules="rulesControl" outlined dense hideDetails="auto" />
          <VSelect v-else :items="options" :label="label" :value="value" :readonly="readonly" :disabled="disabled" @change="(value) => $emit('update:values', insert(values, index, value))" :rules="rulesControl" outlined dense hideDetails="auto" />
        </template>
        <VCheckbox v-else-if="typeof value === 'boolean'" class="mt-0 pt-0" :label="label" :readonly="readonly" :value="value" @change="(value) => $emit('update:values', insert(values, index, !!value))" :rules="rulesControl" hideDetails="auto" />
        <template v-else>
          <VTextField v-if="mask" :label="label" :value="value" :readonly="readonly" :disabled="disabled" @input="(value) => $emit('update:values', insert(values, index, value))" :rules="rulesControl" v-mask="mask" outlined dense hideDetails="auto" autocomplete="chrome-off" />
          <template v-else>
            <template v-if="type === 'date'">
              <DateInput :label="label" :value="value" :readonly="readonly" :disabled="disabled" @input="(value) => $emit('update:values', insert(values, index, value))" :rules="rulesControl" :max="max"/>
            </template>
            <template v-else-if="type === 'tel'">
              <PhoneField :label="label" :value="value" :readonly="readonly" :disabled="disabled" @input="(value) => $emit('update:values', insert(values, index, value))" :rules="rulesControl" />
            </template>
            <template v-else>
              <VTextField :label="label" :type="type" :value="value" :readonly="readonly" :disabled="disabled" @input="(value) => $emit('update:values', insert(values, index, value))" :rules="rulesControl" outlined dense hideDetails="auto" autocomplete="chrome-off" />
            </template>
          </template>
        </template>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { map, toString } from 'lodash-es';
import DateInput from '@/components/general/DateInput/DateInput';
import PhoneField from './PhoneField';
export default {
  name: 'ProfileValue',
  components: {
    DateInput,
    PhoneField,
  },
  props: {
    label: { type: String },
    type: { type: String },
    values: { type: Array },
    rules: { type: Array },
    options: { type: Array },
    mask: { type: String },
    max: { type: String },
    readonly: { type: Boolean },
    disabled: { type: Boolean },
    required: { type: Boolean, default: true },
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    rulesControl() {
      if (this.readonly || this.disabled) return [];
      return map(this.rules, ({ reg, text }) => {
        return (v) => !!new RegExp(reg).test(toString(v)) || text;
      })
    }
  },
  methods: {
    insert(arr, index, value) {
      const result = [...arr];
      result[index] = value;
      return result;
    },
  }
}
</script>

<style module lang="scss">
.root {
  .remove {
    float: right;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
}
</style>
